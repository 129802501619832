export default {
    sort: 'Sortieren',//CM0768
    filter: 'Filter',//CM0930
    loading: 'Laden',//CM0380
    finished: 'Fertige',//
    home: 'Startseite',//CM0141
    get: 'Get',//
    currency: 'W\u00e4hrung',//CM0345
    language: 'Sprache',//CM0655
    order_tracking: 'Nachverfolgung der Bestellung',//CM0825
    shipping_info: 'Versandinfo',//CM0837
    return_policy: 'R\u00fcckgaberichtlinie',//CM0105
    privarcy_policy: 'Datenschutzerkl\u00e4rung & Sicherheitsrichtlinie',//CM0835
    get_app: 'Holen Sie sich die {app} App',//
    color: 'FARBE',//CM0143
    size: 'GR\u00d6SSE',//CM0142
    add_to_bag: 'DER TASCHE HINZUF\u00dcGEN',//
    clear: 'L\u00f6schen',//CM0855
    apply: 'Anwenden',//CM0545_1
    search: 'Suche',//
    i_shipping_for: 'Ich kaufe ein f\u00fcr ...',//CM0112
    cancel: 'Stornieren',//CM0765
    hot_search: 'Topsuche',//CM0705
    items: 'Artikel',//CM1032
    view_more: 'Mehr anzeigen', //CM0153
    sold_out: 'Ausverkauft',//CM0366
    view_all: 'Alle anzeigen',//CM0797
    thanks_subscribe: 'Danke f\u00fcr Ihr Abo',//CM0956
    thanks_enjoy: 'Bitte schauen Sie in Ihrem E-Mail-Posteingang nach. Dort finden Sie Ihren Nutzernamen und das Passwort.',//CM0957
    subscribe_title: 'Melden Sie sich f\u00fcr exklusive Angebote an!',//
    subscribe: 'ABONNIEREN',//CM0091
    smufcme:'Mich f\u00fcr {brand} E-Mails registrieren (Sie k\u00f6nnen sich jederzeit wieder abmelden).',//CM0967
    c_info: '{brand} Info',//
    about_us: '\u00dcber uns',//CM0095
    affiliate: 'Affiliate',//CM0823
    blogger_program: 'Partnerprogramm',//CM0824
    help_support: 'Hilfe & Support',//CM0831
    shipping_policy: 'Versandrichtlinie',//CM0106
    faq: 'FAQ',//CM0099
    order_tracking: 'Nachverfolgung der Bestellung',//CM0825
    coupons: 'Gutschriften',//CM0188
    bonus_points: 'Bonuspunkte',//CM1035
    customer_care: 'Kundendienst',//CM0832
    contact_us: 'Kontakt',//CM0097
    return_policy: 'R\u00fcckgaberichtlinie',//CM0105
    out_of_stock: 'Nicht mehr auf Lager',//CM0621
    wholesale_policy: 'Richtlinie f\u00fcr Gro\u00dfhandel',//CM0108
    drop_shipping_policy: 'Richtlinie f\u00fcr Direktversand',//CM0109
    i_p_rights: 'Intellectual Property Rights',
    privacy_policy: 'Datenschutzerkl\u00e4rung & Sicherheitsrichtlinie',//CM0835
    term_notice: 'Allgemeine Gesch\u00e4ftsbedingungen',//CM0836
    category: 'Kategorie', //CM1019
    new_in: 'Neu eingetroffen',//CM0001
    bag: 'Tasche',//CM1021
    account: 'Konto',
    flash_sale: 'Flash Deals',//CM0772
    ends_in: 'Endet in', //CM1009
    free_gift: 'Werbegeschenke',
    multi_add: 'Entschuldigung, Sie k\u00f6nnen nur einen Artikel als Gratisgeschenk ausw\u00e4hlen. Wenn Sie einen weiteren Artikel hinzuf\u00fcgen m\u00f6chten, ersetzt dieser das bereits hinzugef\u00fcgte Gratisgeschenk.',
    learn_more: 'Lern mehr',
    email: 'E-Mail',
    recommend: 'Empfehlen',
    confirm: 'best\u00e4tigen Sie',
    get_the_look:"sehen Sie sich an", //CM1187
    previous:"vorher", //CM1188
    next:"weiter", //CM1189
    beauty:"Sch\u00f6nheit", //CM0322
    lingerie:"Unterw\u00e4sche",  //CM0135
    shop_the_look:"Shoppe den Look",
    good_to_know:"Gut zu wissen", //CM1131
    time_left:"Verbleibende Zeit", //CM0363
    limited_titme:"Begrenzte Zeit", //CM0907
    flash_sale:"Blitzverkauf", //CM0451
    new:"NEU", //CM0456
    more:"Mehr",
    qty:"Menge", //CM0200
    reviews:"Bewertungen", //CM0370
    recommend:"Empfehlen", //CM1034
    you_can_match_with:"Sie k\u00f6nnen mit \u00fcbereinstimmen", //CM1012
    related_searches:"Verwandte Suchanfragen", //CM1000
    too_small:"Zu klein", //CM0373
    ture_to_size:"Guter Sitz", //CM0372
    to_large:"Zu gro\u00df", //CM0371
    translare_to:"\u00dcbersetzen nach", //CM1128
    translate:"\u00dcbersetzen", //CM1127
    overall_fit:"Gesamtpassform",
    share_the_product_to:"Teilen Sie das Produkt mit",
    description:"Bezeichnung", //CM1130
    ingredients:"Zutaten", //CM1132
    estimated_time:"Voraussichtliche Ankunftszeit", //CM1031
    model_stature:"Modell-Statur", //CM0367
    model_wear:"Model Wear", //CM1015
    height:"K\u00f6rpergr\u00f6\u00dfe", //CM0971
    hips:"H\u00fcfte", //CM0174
    shipping_information:"Versandinformationen", //CM0149
    sizing_help:"Gr\u00f6\u00dfenhilfe", //CM1014
    product_details:"Produktdetails", //CM1013
    you_may_also_likes:"Sie k\u00f6nnen auch m\u00f6gen", //CM0154
    if_the_item_comes_back:"Wenn der Artikel innerhalb von 30 Tagen wieder auf Lager ist, senden wir Ihnen eine E-Mail.", //CM1136
    you_haveArrival_reminder:"Sie haben die Ankunftserinnerung bereits abonniert", //CM1125
    sold_out_notification:'Der Artikel ist ausverkauft, klicken Sie auf "Benachrichtigen", um eine Benachrichtigung \u00fcber die Auff\u00fcllung zu erhalten', //CM1126
    un_subscribe:"ABBESTELLEN", //CM1123
    notify_me:"MICH BEACHTEN", //CM1124
    add_to_cart:"Zum Warenkorb hinzuf\u00fcgen", //CM0145
    product_measurements:"Produktabmessungen", //CM1183
    view_size_information:"Weitere Gr\u00f6\u00dfeninformationen anzeigen", //CM1129
    beauty_not_supported:"R\u00fcckgabe und Umtausch von Waren werden nicht unterst\u00fctzt.", //CM1134
    easy_return:"Einfache R\u00fcckkehr", //CM1011
    find_simlar:"\u00c4hnliches finden", //CM1135
    babies:"Babys",
    babies_look:"Babys Look",
    shipping_method:"Versandart", //CM0623
    ship_current_address:"Dieser Artikel kann nicht an die aktuelle Adresse versendet werden", //CM1184
    show:"Show",
    index_subscribe_agree:"Indem Sie sich anmelden, stimmen Sie unserer zu",
    privacy_cookie:"Datenschutz & Cookie",
    terms_conditions:"Terms & amp; Bedingungen",
    and:"und",
    register:"Registrieren", //CM1204
    us_address_deliverd:"Die {value} wird voraussichtlich zugestellt am",
    us_address:"U.S.-Adresse",
    enter_email_address: "Geben Sie Ihre E-Mail-Adresse ein",
    mystery_offer: "MYSTERY OFFER",
    get_mystery_offer: "Ich m\u00f6chte mein Mystery-Angebot nicht",
    has_subscribe_to:"Die E-Mail wurde bereits von anderen verwendet. Bitte geben Sie eine andere E-Mail an.",
    no_more_data:"Keine weiteren Daten", //CM0381
    add:"hinzuf\u00fcgen",
    shopping_for:"Ich kaufe ein f\u00FCr", //CM0112
    buy_now: 'Jetzt kaufen', //CM0665
    visual_search:"Visuelle Suche",
    upload_image_search_photo:"Machen Sie ein Foto oder laden Sie ein Bild hoch, um \u00e4hnliche Artikel zu finden.",
    recommend:"Empfehlen", //CM1034
    new_arrivals:"Neu eingetroffen", //CM0340
    price:"Preis", //CM0946
    take_a_photo:"Mach ein Foto",
    upload_a_image:"Laden Sie ein Bild hoch",
    photo_upload_image:"Sie k\u00f6nnen jetzt nach dem gew\u00fcnschten Artikel suchen, indem Sie ein Foto aufnehmen oder ein Bild hochladen",
    not_match_fund:"Keine \u00dcbereinstimmung gefunden",
    please_try_clearer_image:"Bitte versuchen Sie es mit einem klareren Bild oder platzieren Sie das Produkt, nach dem Sie suchen m\u00f6chten, in der Mitte des Bildes",
    try_text_to_search:"Versuchen Sie es mit Text, um nach dem gew\u00dcnschten Element zu suchen",
    try_text_search:"Testen Sie die Textsuche",
    try_again_with_image:"Versuchen Sie es erneut mit Bild",
    or:"oder", //CM0547
    low_price:"Niedriger Preis",
    high_price:"Hoher Preis",
    register_success_message:"\u00DCberpr\u00FCfen Sie Ihren Posteingang auf Ihren {coupon} und die {point}-Pr\u00E4mie! Wir haben {email} eine Best\u00E4tigungs-E-Mail gesendet. Der Link ist nur 72 Stunden g\u00FCltig.", //CM1111
    register_success_message_phone: "\u00DCberpr\u00FCfen Sie Ihren Posteingang auf Ihren {coupon} und die {point}-Pr\u00E4mie!",
    sign_in:"Anmelden", //CM0354
    or_join_with:"Oder machen Sie mit", //CM1236
    captcha_not_empty:"Das CAPTCHA kann nicht leer sein", //google
    forget_password:"Passwort vergessen", //CM0458
    email_address:"E-Mail-Adresse", //CM0272
    password:"Passwort", //CM0432
    six_characters_min:"Mindestens 6 Zeichen", //CM1100
    twenty_characters_max:"Maximal 20 Zeichen", //CM1101
    no_emojis:"Keine Emojis", //CM1102
    belong_get_points_500:"500 Punkte geh\u00F6ren Ihr sofort", //CM1178
    finshed_register_shop:"Schlie\u00DFen die Registrierung ab und erhalten Points Reward zum Einkaufen", //CM1179
    register_privacy_policy:"Ich stimme zu {term} und {privacy}", // CM1116 CM0462
    term_of_services:"Nutzungsbedingungen", //CM0103
    privacy_policy_only:"Datenschutz", //CM0104
    create_account:"Konto erstellen", //CM0491
    coupon:"Gutschein", //CM0543
    points:"Punkte", //CM1202
    welcome_to:"Willkommen bei", //CM1107
    registration_success:"Registrierung erfolgreich", //CM1108
    reset_password:"Passwort zur\u00FCcksetzen", //CM0443
    please_send_email_auto_matically:"Bitte geben Sie die E-Mail-Adresse ein, mit der Sie sich bei uns registriert haben. Eine E-Mail zur Passwortwiederherstellung wird automatisch an Sie gesendet.", //CM1089
    send_email:"E-Mail senden", //CM1090
    link_rest_password_to:"Der Link zum Zur\u00FCcksetzen des Passworts wurde gesendet an", //CM1091
    create_new_password_link_easy:"Um Ihr neues Passwort zu erstellen, klicken Sie nur auf den Link in der E-Mail, die wir Ihnen geschickt haben - ganz einfach", //CM1092
    check_agin_enter_email_send:"Nicht erhalten? \u00DCberpr\u00FCfen Sie Ihre Junk-Mail oder klicken Sie auf den untenstehenden Link und wir senden Ihnen eine neue zu.", //CM1093
    still_online_help:"Wenn Sie es immer noch nicht finden k\u00F6nnen, klicken Sie auf \"Online-Hilfe\", um Hilfe zu erhalten", //CM1094
    three_hours_expires:"Warnung: Link l\u00E4uft in drei Stunden ab!", //CM1095
    resend_email:"E-Mail zur\u00FCcksenden", //CM1115
    advanced_messenger:"Erweiterter Nachrichtendienst", //CM0948
    discount_deals_weekly:"Sie erhalten w\u00F6chentlich Rabattcodes und Sonderangebote", //CM0950
    response_order_infomation:"Sie k\u00F6nnen automatisch Bestellinformationen abrufen", //CM0951
    online_custoer_service:"Sie k\u00F6nnen einen Online-Kundendienst erhalten", //CM0952
    continue:"Weiter", //CM0953
    password_incorrect:"Passwort falsch! Passwort vergessen?", //CM1097
    try_again:"Erneut versuchen", //CM1099
    emaul_no_register_now:"Dieses E-Mail-Konto ist nicht registriert, m\u00F6chten Sie sich jetzt registrieren", //CM1103
    no_thanks:"NEIN, DANKE", //CM1104
    register_has_login_now:"Dieses E-Mail-Konto wurde registriert, m\u00F6chten Sie sich jetzt einloggen?", //CM1105
    log_in_now:"Jetzt einloggen", //CM1106
    view_on_app:"Ansicht in der App", //google
    today_no_change:"Sie haben heute keine Chancen mehr, gehen Sie einkaufen!", //CM1140
    go_shopping:"Zum Einkaufen", //CM1141
    player_again:"Versuch es noch einmal", //CM1142
    congratulations:"Herzlichen Gl\u00FCckwunsch", //CM0940
    please_try_again:"Oops, bitte versuchen Sie es noch einmal", //CM1145
    go:"LOS", //CM1180
    my_prize:"Meine Preise", //CM1081
    rules:"Regeln", //CM0777
    download_app:"APP herunterladen", //CM1082
    expire_in:"Ablaufen in", //CM1250
    please_select_size:"Bitte w\u00e4hlen Sie eine Gr\u00f6ße", // google
    ok:"Ok",// google
    my_prizes_rules:"Meine Preise & Regeln", //CM1192
    you_have:"Sie haben", //CM1038
    changes:"Chancen", //CM1039
    customer_services_prizes:"Bitte machen Sie einen Screenshot und {Wert}, um den Kundendienst f\u00fcr Ihren Preis zu kontaktieren", //google
    click_here:"Klicken Sie hier", //CM1064
    first_lottery_login:"Bitte loggen Sie sich erst ein, um zu spielen", // google
    count_items: "{total} Artikel",
    today_no_change_for_app:"Du hast heute keine Chance mehr. Erhalten Sie 1 zusätzliche Chance auf {name} APP!",
    email_entered_try_again:"Die von Ihnen eingegebene E-Mail ist ung\u00fcltig. Bitte \u00fcberpr\u00fcfen Sie Ihre E-Mail und versuchen Sie es erneut.",
    you_are_registerd:"Sie sind bereits registriert",
    subscribe_to_get_lottery_change:"*Das Angebot kann nur von neuen Abonnenten eingelöst werden. Mit der Anmeldung erklären Sie sich damit einverstanden, regelmäßig Marketing-E-Mails von {name} an die bei der Anmeldung angegebene E-Mail-Adresse zu erhalten.",
    invalid_password:"Ung\u00fcltiges Passwort",
    lucky_draw:"LUCKY\nDRAW",
    lucky_draw_nobreak:"LUCKY DRAW",
    fill_in_password:"Geben Sie das Passwort ein",
    subtext: "Wir m\u00F6chten Ihnen Benachrichtigungen \u00FCber die neuesten Nachrichten und Updates senden.",//CM1008
    no_thanks: "Nein, Danke",//CM1117
    allow: "Erlauben", //CM1118
    draw_now:"Jetzt\nZeichnen",
    take_simlar_item_image:"Machen Sie ein Foto oder laden Sie ein Bild hoch, um ähnliche Artikel zu finden.",
    camera:"KAMERA",
    images:"IMAGES",
    shop_similar:"Ähnlich kaufen",
    privacy_request_center: "DATENSCHUTZ ANFRAGEZENTRUM",
    valid_code_sent: "Der Verifizierungscode wurde versandt. Bitte \u00fcberpr\u00fcfen Sie Ihre E-Mail, um den Verifizierungscode zu \u00fcberpr\u00fcfen.",
    sending: 'Sending',
    send: 'Senden Sie',
    submit_successfully: 'Erfolgreich abgeschickt',
    thanck_request: 'Vielen Dank f\u00fcr Ihre Anfrage. Ein Mitarbeiter wird Ihre Anfrage pr\u00fcfen und Sie unter der von Ihnen angegebenen E-Mail-Adresse kontaktieren.',
    country_is_required: 'Bitte wählen Sie Ihr Land/Ihre Region',
    name_is_required: 'Bitte geben Sie Ihren vollständigen Namen ein',
    account_is_required: 'Die von Ihnen eingegebene E-Mail ist ung\u00fcltig', //todo
    account_is_invalid: 'Die von Ihnen eingegebene E-Mail ist ung\u00fcltig',
    code_is_required: 'Bitte Verifizierungscode eingeben',
    description_is_required: 'Bitte beschreiben Sie Ihr Problem ausf\u00fchrlich',
    country: 'Land/Region',
    place_holder_country: 'Bitte wählen Sie Ihr Land/Region',
    full_name: 'Voller Name',
    place_holder_fullname: 'Der vollständige Name sollte 2-100 Zeichen enthalten',
    your_account: 'Ihr {webname}-Konto',
    place_holder_account: 'Sie m\u00fcssen die Ihrem Konto zugeordnete E-Mail-Adresse angeben, um die in unserem System gespeicherten personenbezogenen Daten zu finden',
    verification_code: 'Verifizierungscode',
    detailed_description: 'Detaillierte Beschreibung',
    place_holder_description: 'Bitte geben Sie eine detaillierte Beschreibung ein',
    i_agree_to_the_privacy_policy: 'Ich erkläre mich damit einverstanden, dass {webname} die Daten, die ich oben angegeben habe, gemäß der {policy} sammelt und verwendet, um meine Anfrage zu bearbeiten',
    submit: 'Submit',
    next_step: 'NÄCHSTER SCHRITT',
    privacy_policy: 'Datenschutz- und Cookie-Richtlinie',
    verify_email_comfirm:"Bitte vergewissern Sie sich, dass die eingegebene E-Mail {value} korrekt ist. Wenn Sie die Registrierung fortsetzen möchten, klicken Sie bitte auf Bestätigen.",
    how_to_pay:"Wie man bezahlt",
    share_to:"Teilen nach",
    copy_link:"Link kopieren",
    copy_success:"Kopie erfolgreich",
    get_draw_chance:"1 zusätzliche Chance erhalten",
    support_pre_sale_title: 'Vor dem Kauf',
    support_pre_sale: 'Wenn Sie Fragen zum Einkauf haben, dann nutzen Sie den Online-Chat, um mehr Informationen zu erhalten.',
    support_after_sale_title: 'AFTER-SALE',
    support_after_sale: 'Nach dem Kauf',
    online_help: 'Onlinehilfe',//CM0915
    submit_ticket: 'Ein Ticket erstellen',//CM0864
    search_for: 'Ich kaufe ein f\u00FCr ...',
    clear_all: 'Alles l\u00F6schen',//CM0931
    empty_cart_description: 'Ihr Warenkorb ist zurzeit leer',//CM0521
    you_selected: 'Sie w\u00E4hlten {color}',//CM0475
    uploading_photo: 'Suche durch Hochladen eines Fotos',//CM1298
    upload_photo: 'Foto hochladen',//CM0257
    price_low_to_high: 'Preis niedrig bis hoch',//CM0845
    price_high_to_low: 'Preis hoch bis niedrig',//CM0846
    shop_similar:'\u00C4hnlich kaufen',//CM1304
    uploading: 'Hochladen',
    empty_filter_page: 'Oh, oh! Wir konnten nicht finden, wonach Sie suchen. Versuchen Sie es mit weniger Filtern!',//CM0955
    recently_search: 'Recently Searched', //CM1017
    my_account: 'Mein Konto', //CM0296
    my_orders: 'Meine Bestellungen', //CM0479
    my_message: 'Meine Nachricht',//CM1119
    my_coupons: 'Meine Gutscheine',//CM1120
    my_points: 'Meine Punkte', //CM1121
    view_bag: 'WARENKORB ANZEIGEN',//CM0183
    sign_out: 'Abmelden',//CM0254
    total: 'Gesamt',//CM0180
    check_it_in_coupons: 'Pr\u00fcfen Sie es in [Meine Gutscheine]',
    got_it: 'VERSTANDEN',
    congratulations_get: 'Herzlichen Gl\u00fcckwunsch zu den {money}!',
    wait: 'Warten Sie',
    limit_coupon_unused: 'Sie haben noch eine begrenzte Anzahl von unbenutzten Gutscheinen',
    limit_24_time: 'NUR 24 STUNDEN',
    return_to_site: 'ZUR\u00fcCK ZUR SEITE',
    get_offer:"Angebot erhalten",
    enter_email_to_reveal_offer:'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Sonderangebot anzuzeigen', //CM1256
    buy:"Kaufen", //CM0242
    simply_special_price:"Scannen Sie einfach den QR-Code zum Installieren und machen Sie sich bereit zum SONDERPREIS!", //CM1209
    model:"Modell", // CM0970 
    size_guide:"Gr\u00F6\u00DFenanleitung", // CM0156
    weight:"Gewicht",
    customer_reviews:"Kundenrezensionen", // CM1018
    size_chart:"Gr\u00F6\u00DFentabelle", //CM0476
    support:"Die Unterstützung", //CM0094
    payment_method:"Zahlungsmethode", //CM0389
    website_rights_reserved:"{name} ist eine eingetragene Handelsmarke von {domain}. Alle Rechte vorbehalten.", //CM0834
    follow_us:"folgen sie uns", //CM0093
    shipping_time:"Versandzeit", //CM0377
    costs:"Kosten", //CM0965
    shipping_to:"Versand nach", //CM0972
    receiving_time:"Empfangszeit", //CM0375
    precessing_time:"Bearbeitungszeit", //CM0376
    products: 'Produkte',
    promotions: 'Werbeaktionen',
    go_to_bag: "ZUR TASCHE",
    total_peice_price:"Total {total} St\u00fcck | {price}",
    reset_qty:"Zurücksetzen",
    best_sellers:"Meistverkaufte",
    best_sellers_in:"Die Bestseller in",
    
    product_unavailable:'Produkt derzeit nicht verfügbar',
    save:"Speichern", //CM0212
    set:"einstellen",
    buy_together:"Zusammen kaufen",
    add_successful:"Erfolgreich hinzufügen",
    collect:"collect",
    collect_all:"alles sammeln",
    free_cash: 'Gratis-Cash-Geschenk!',
    win_100: '100 % GEWINNRATE!',
    ann_c_1: 'Jeder registrierte Benutzer hat 1 Chance pro Tag und 1 zusätzliche Chance nach dem Bezahlen!',
    ann_c_2: '*Die tägliche Chance wird vom 17. August bis zum 23. August um 00:00 UTC aktualisiert.',
    counpon_has_sent: '{coupon} wurde an Ihre Geldbörse gesendet.',
    expire_content: 'Läuft in 24h ab!!! Bezahlen Sie es jetzt an der Kasse! <br/> *Kann nicht zum Abzug von Versandkosten verwendet werden.',

    lucky_winner: 'GLÜCKLICHER GEWINNER',
    view_prizes:"Preise ansehen",
    continue_shopping: 'Einkauf fortsetzen',
    go_wallet: 'Zur Brieftasche',
    open:'Öffnen',
    shopping: 'Einkaufen',
	check: 'Check',

    day1:'1. Tag',
    day2:'2. Tag',
    day3:'3. Tag',
    day4:'4. Tag',
    day5:'5. Tag',
    day6:'6. Tag',
    day7:'7. Tag',
    specialGift: 'Spezielle Geschenk',
    specialGifts: 'Spezielle Geschenke',
    today: ' Heute',
    checkInSuccess: 'Erfolgreich einchecken',
    checkDays: 'Sie haben {day} Tage lang eingecheckt.',
    checkDay: 'Sie haben {day} Tag lang eingecheckt.',
    checkRules: 'Einchecken-Regeln zum Jahrestag',
    openGift: 'Tippen und öffnen das besondere Geschenk!',
    openNotificationTip: 'Wir können Ihnen keine Benachrichtigungen senden. Bitte gehen Sie zu den Einstellungen, um es zu öffnen.',
    checkDaysLeft: 'Sie haben an {daysHave} aufeinanderfolgenden Tagen eingecheckt. Checken Sie für weitere Tage ein, um mehr Überraschung zu bekommen.',
    remindMe: 'Erinnere mich daran',
    downloadNow: 'Jetzt herunterladen',

    alreadyChecked: 'Sie haben heute bereits eingecheckt',
    checkedNow: '50.000+ GESCHENKE, BEEILE DICH!!!',
    checkInNow: 'Jetzt einchecken',
    gift: 'Geschenk',
    v_shop:"V-Shop",
    comments:"Kommentare",
    chart_ask_question:"Chatten oder Fragen stellen",
    tap_mystery_gift: 'Tippen Sie, um das Mystery-Geschenk zu öffnen!',
    mystery_gift: 'Geheimnisvolles Geschenk',
    addtohometext: '{site} der Startseite hinzuf\u00FCgen',
    // sizechart
    inches:'Zoll',
    centimeters: 'cm',
    sizeconversation:'Größengespräch',
    size:'Größe',
    bust: "Büste",
    waist:"taille",
    hip: "Hüfte",
    length: "Länge",
    size_chart:"Größentabelle",
    size_help: "Größenhilfe",
    fit_type:"Fit-Typ",
    stretch:"Strecken",
    product_measurements:"Produktmaße",
    body_measurements:"Körpermaße",
    bust_size: 'Büste',
    waist_size:"Taillenumfang",
    hip_size:"Hüftumfang",
    your_bust:"Deine Büste",
    your_waist:"Deine Taille",
    your_hips:"Deine Hüften",
    measurements: "Wir empfehlen dringend, dass Sie Ihre Maße nehmen lassen und sie als Referenz verwenden, wenn Sie eine Artikelgröße auswählen. Messen Sie sich, indem Sie der Anleitung unten folgen.",
    bust_note: "Messen Sie unter Ihren Armen um den vollsten Teil Ihrer Brust. Achten Sie darauf, das Maßband gerade zu halten!",
    waist_note: "Stellen Sie sicher, dass das Maßband bequem sitzt, wenn Sie an der schmalsten Stelle Ihrer natürlichen Taille messen.",
    hip_note: "Stellen Sie sich mit geschlossenen Füßen hin und messen Sie an der breitesten Stelle Ihrer Hüfte.",
    length_note: "Um Ihre Fußlänge zu messen, setzen Sie sich auf einen Stuhl und legen Sie den Fuß flach auf ein Blatt Papier. Zeichnen Sie den Fuß nach, indem Sie einen Bleistift vertikal halten und den Fuß umreißen. Messen Sie mit einem Lineal den Abstand von der Unterseite der Ferse bis zur Spitze des längsten Zehs. Notieren Sie das Ergebnis sowohl in Zoll als auch in Zentimetern und verwenden Sie diese Maße, um Ihre Größe in der Tabelle zu finden.",
    product_measurements_message:"* Diese Daten wurden durch manuelles Messen des Produkts erhalten, sie können um 1-2 cm abweichen.",
    measure_product_size:"Wie misst man die Produktgröße?",
    body_measurements_message:"* Abhängig von Ihrem Körpertyp und Ihren Kleidungsgewohnheiten dienen die obigen Größen nur als Referenz.",
    measure_body_size:"Wie misst man seinen Körper?",
    measure_body_size_bust:"Messen Sie den Umfang an der breitesten Stelle Ihrer Brust.",
    measure_body_size_waist:"Messen Sie Ihre Taille an der dünnsten Stelle.",
    measure_body_size_hip:"Messe den vollsten Teil deiner Hüften.",
    size_fit:"Größe & Passform",
    height_waist:"Höhe/Gewicht",
    measure:"Messungen",
    model_wear:"Modell Bekleidung",

    one_size: 'Einheitsgröße',
    code:"code",

    system_update: 'Systemaktualisierung',
    system_update_back: 'Um Ihnen besser dienen zu können, aktualisieren wir unsere Systeme. Wir sind gleich wieder zurück!',
    policy:"Police",
    after_pay_faq: "Afterpay FAQ",
    klnara_policy_message:"Jetzt einkaufen, später bezahlen mit Klarna!",
    zip:"Zip",
    vip:"Vip",
    delivery:"Lieferung",
    about_my_wallet:"Über My Geldbörse",
    about_early_bird:"Über EarlyBird",
    clear_pay:"Clearpay",
    sold_percent: "{percent} % verkauft",
    purchase_limit: "Kauflimit: {qty} Menge",

    agree_login_policy_message:"Bitte best\u00E4tigen Sie vor dem n\u00E4chsten Schritt die Nutzungsbedingungen und die Datenschutzerkl\u00E4rung.", //CM0856
    register_with:"Registrieren bei", //CM0463
    enjoy_services:", um in den Genuss von personalisierten Diensten zu gelangen,", //CM0465
    including:"einschlie\u00DFlich", //CM0466
    online_order_status:"Status der Online-Bestellung", //CM0469
    exclusive_emails:"Exklusive E-Mails", // CM0470
    wishlist:"Wunschliste", // CM0344
    checkout_preferences:"Checkout-Pr\u00E4ferenzen", // CM0471
    sign_In_Facebook:"Mit Facebook anmelden", // CM1233
    the_counry_not_support_message:"Das aktuelle Land/die Region unterstützt die Anmeldung/Registrierung per Telefonnummer nicht, bitte verwenden Sie Ihre E-Mail zur Anmeldung/Registrierung",
    enter_the_password:"Geben Sie das Passwort ein",
    enter_the_code_number:"Geben Sie die Codenummer ein",
    enter_phone_number:"Geben Sie Ihre Telefonnummer ein",
    incorrect_phone_number:"Falsche Telefonnummer",
    password_change_success:"Passwort erfolgreich geändert",
    confirm_password:"Bestätigen Sie das Passwort",
    verification_code_error_message:"Leider ist der eingegebene Verifizierungscode falsch oder ungültig.",
    phone_register_has_already:"Die Handynummer wurde bereits einem Konto zugewiesen, möchten Sie sich jetzt anmelden?",
    phone_number_no_register_now:"Diese Telefonnummer ist nicht registriert, möchten Sie sich jetzt registrieren?",
    location:"STANDORT",
    telephone_number:"RUFNUMMER",
    resend:"Erneut senden",
    use_verification_code:"Verwenden Sie den Verifizierungscode, um sich anzumelden",
    sin_in_via_password:"Anmeldung mit Passwort",
    verifycation_code_success:"Überprüfungscode erfolgreich gesendet",
    confirm_your_phone_number_change:"Bestätigen Sie Ihre Telefonnummer und geben Sie den richtigen Code ein, um Ihr Passwort zu ändern.",
    inconststent_password:"Inkonsistente Passwörter",
    register_via_telephone_number:"Anmeldung über Telefonnummer",
    register_via_email_address:"Anmeldung per E-Mail Adresse",
    signin_via_telephone_number:"Anmeldung über die Telefonnummer",
    signin_via_email_address:"Anmeldung über E-Mail Adresse",
    almost_sold_out: "Fast ausverkauft",

    telephone: 'Telefon',
    forget_your_password: 'Haben Sie Ihr Passwort vergessen?',
    choose_your_location: 'Bitte wählen Sie Ihren Standort',
    installment_forms: 'Ratenzahlungsformulare',
    interest_free: 'zinsfrei',
    staging_rates: 'Staging-Preise variieren je nach Bank',
    accpect: 'akzeptieren',
    sing_receive_discount_now:"Melden Sie sich jetzt an, um von den folgenden Rabatten zu profitieren!",
    received_following_discount:"Sie haben die folgenden Ermäßigungen erhalten",
    received_vip_exclusive_discount:"Sie haben einen exklusiven VIP-Rabatt erhalten",
    pick_my_gift:"Wähle mein Geschenk",
    choose_add_your_cart:"Bitte wählen Sie Ihr Lieblingsgeschenk aus, um es Ihrem Einkaufswagen hinzuzufügen.",
    reselect_gift_your_bag:"Sie können das Geschenk in Ihrer Tasche erneut auswählen.",
    check_out: 'CHECKOUT',
    gift_to_your_bag: 'Die Beauty Box wurde in Ihre Tasche gelegt, Sie können jederzeit vor dem 6. Dezember zur Kasse gehen.',
    foot_length: 'Fußlänge',
    network_error:'Netzwerkfehler',
    subscription:"Abonnement", //CM0089
    invalid_email: 'Die eingegebene E-Mail ist ung\u00FCltig. Bitte \u00FCberpr\u00FCfen Sie Ihre E-Mail und versuchen Sie es erneut.',

    size_conversion: 'Größenkonvertierung',
    international_size_reference: 'Der internationale Größenvergleich dient nur als Referenz, die spezifische Größe basiert immer noch auf der tatsächlichen Größe der Kleidung.',
    country_size: '{country}-Grösse',
    new_register_privacy_policy:"Mit der Anmeldung zu Ihrem Konto erklären Sie sich mit unseren Nutzungsbedingungen und unserer Datenschutzrichtlinie einverstanden.", // CM1116 CM0462
    incorrect_email:"Falsche E-Mail",
    enter_code_number:"Code-Nummer eingeben",
    enter_the_telephone_number_or_email:"Bitte Telefon oder E-Mail eingeben",
    enter_the_verification_code:"Geben Sie den Verifizierungscode ein",
    send_verification_code:"Ein Verifizierungscode wird an {value} gesendet",

    back: 'Zurück',
    free_shipping: 'KOSTENLOSER',
    all: 'Alle',
    reset: 'Restaurieren',
    done: 'Terminé',
    price_range: 'Preisklasse({currency})',
    price_range_only: 'Preisspanne',
    repick: 'Neu holen',
    pick: 'wählen',
    sure_delete_item: 'Sind Sie sicher, dass Sie dieses Produkt löschen möchten?',
    sure_delete_items: 'Sind Sie sicher, dass Sie dieses Produkt(e) löschen möchten?',
    recommend_add_item: 'Empfohlene Zusatzartikel',
    free_gifts: 'Kostenlose Geschenke',

    available: 'Erhältlich',
    not: 'Nicht',
    select_anthoer_paymentmethod: 'Bitte wählen Sie eine andere Zahlungsmethode aus',
    sign_to_sync_bag: 'Melden Sie sich an, um Ihre Einkaufstasche zu synchronisieren',
    ship_to_different_address: 'Versand an eine andere Lieferadresse',
    address_book: 'Adressbuch',
    first_name: 'Vorname',
    last_name: 'Familienname',
    phone_number: 'Telefonnummer',
    address_line_1: 'Adresse',
    address_line_2: 'Einheit',
    address_line_1_placeholder: 'Straße,Adresse,Name des Unternehmens,C/O',
    number: 'Nummer',
    neighborhood: 'Nachbarschaft',
    city:'Stadt',
    state: 'Zustand',
    zip_code: 'PLZ',
    enter_it_manually: 'Manuell eingeben',
    optional: 'Optional',
    find_simillar: 'Finden Sie ähnliche',
    ships_to_country: 'Versand nach: {country}',
    shopping_bag: 'Warenkorb',
    secure_payment: 'Sichere Bezahlung',
    shopping_bag_empty: 'Ihre Einkaufstasche ist leer!',
    sign_in_to_view_bag: 'Melden Sie sich an, um Ihren Warenkorb anzuzeigen und mit dem Einkaufen zu beginnen',
    shop_now: 'Jetzt einkaufen',
    sign_in_register: 'Entrar / Registre-se',
    secure_checkout: 'Sichere Kasse',
    create_account_more_discount: 'Erstellen Sie ein neues Konto, um mehr Rabatt zu erhalten',
    continue_to_checkout: 'WEITER ZUR KASSE',
    checkout_guest_with_email: 'Checkout als Gast mit Ihrer E-Mail',
    guest_checkout: 'Gästekasse',
    checkout: 'KAUFEN',
    move_to_wishlist: 'Zur Wunschliste verschieben',
    delete: 'Löschen',
    move_all: 'Alle verschieben',
    you_like_fill_it: 'Vielleicht möchten Sie es mit ... auffüllen',
    add_to_wish_list_confirm: 'Sind Sie sicher, dass Sie das Produkt aus dem Warenkorb in die Wunschliste verschoben haben?',
    apply_coupon: 'Gutschein anwenden',
    ivalid_items_title: 'Diese Artikel sind vorübergehend nicht auf Lager.',
    count_items: '{total} Produkte',
    add_shipping_insurance: 'Versandversicherung hinzufügen',
    place_order: 'Bestellung abgeben',
    apply_points: 'Punkte anwenden',
    max_available_count: 'Maximal verfügbar {count}',
    apply_cash: 'Wenden Sie Bargeld an',
    wallet: 'Geldbörse',
    order_confirmation: 'Auftragsbestätigung',
    shipping_address: 'VERSANDADRESSE',
    switch_payment_method: 'Zahlungsmethode wechseln',
    change_card_information: 'Karteninformationen ändern',
    process_payment: 'Wir verarbeiten Ihre Zahlung',
    do_not_leave_refresh: 'Por favor, no dejes ni actualices',
    price_per_installment: '{price}/monat',
    order_summary: 'Bestellübersicht',
    shipping_insurance: 'Versandversicherung',
    order_number: 'Best.-Nr',
    item_total: 'gesamte Artikel',
    shipping_price: 'Versandpreis',
    order_total: 'Bestellsumme',

    required: 'Erforderlich',
    invalid_email_address: '{email} ist keine gültige E-Mail Adresse',
    first_name_reg_default: 'Vorname sollte nicht reine numerische 1-40 Zeichen enthalten',
	last_name_reg_default: 'Nachname sollte nicht reine numerische 1-40 Zeichen enthalten',
    phone_reg_10_11: 'Geben Sie mindestens 10-11 Zahlen ein.',
	phone_reg_ae: 'Telefonnummern sollten mit 50/52/54/55/56/58/2/3/4/6/7/9 beginnen, gefolgt von 7 beliebigen Ziffern.',
	phone_reg_sa: 'Telefonnummern sollten mit 50/53/55/51/58/59/54/56/57/11/12/13/14/16/17/811 beginnen, gefolgt von 7 beliebigen Ziffern.',
	phone_reg_default: 'Falsches Telefonnummernformat (nur Zahlen, keine Formatierung)',
    please_enter_5_digits: 'Bitte geben Sie 5 Ziffern oder 5 Ziffern mit einer 4-stelligen Zahl ein (z. B. 20001 oder 20001-0000)',
	wrong_zip_code: 'Falsche Postleitzahl! Bitte bieten Sie uns ein- oder zweibuchstabige und ein-zwei-stellige Zahlen mit einem Leerzeichen am Ende mit einer Ziffer und zwei Buchstaben an. (zB TN37 7HL,CW8 3AD)',
	wrong_address_br: 'Das Adressformat ist falsch, füllen Sie das Formular von [01310-000] aus',
    not_vaild_cpf: '{value} ist kein gültiger CPF',

    credit_debit_card:'Kredit-/Debitkarte',
    billing_address: 'Rechnungsadresse',
    select_a_card: 'Wähle eine Karte',
	use_new_card: 'Nutze eine neue Karte',
    waiting_for_payment:"Warten auf Zahlung",
	view:"sicht",

    auto_search_address:'ADRESSENFINDER: Suche nach Postleitzahl, Straße oder Adresse',
    cant_find_address: "Adresse nicht gefunden?",
    select_pay_method: 'Bitte wählen Sie eine Zahlungsart aus!',
    only_left: 'nur {count} übrig',
    pay_now: 'Zahlen Sie jetzt',
    card_number: 'Kartennummer',
    expiration_date: 'Verfallsdatum',

    confirm_change_address: 'Einige Artikel, die aus US-Geschäften versandt werden, können nicht zur Kasse gestellt werden. Möchten Sie die Adresse wirklich ändern?',
    edit: 'Bearbeiten',
    add_new_address: 'Füge eine neue Adresse hinzu',
    details: 'Detail',

    use_following_coupons: 'Sie können die folgenden Gutscheine für die Artikel in Ihrem Einkaufswagen verwenden, bitte verwenden Sie sie auf der Bestellseite.',
    cancel_info: 'Möchten Sie die Zahlung wirklich stornieren? Wenn Ihre Bestellung nicht in {countdown} bezahlt ist, wird sie storniert.',
	continue_to_pay: 'Weiter zu Bezahlen',
	confirm_cancel: 'Bestätigen Stornieren',
    protectAccount: 'Ihre Kontodaten sind vollständig geschützt und werden aus keinem Grund an Dritte weitergegeben.',
    available: 'Erhältlich',
    coupons_tip: "Gutscheine/Prämien können im nächsten Schritt verwendet werden.",
    invalid_address: 'Falsches Adresseformat',


    // Me
    login_or_register:"Melden / Registrieren",
    introduce_to_others:"Stellen Sie sich anderen vor",
    sure_delete_items:"Möchten Sie die Elemente wirklich löschen?",
    guest_user:"Gast-Benutzer",
    set_password:"Passwort festlegen",
    temporay_account_set_password:"Dies ist Ihr vorläufiges Konto. Richten Sie ein Passwort ein, um sich zu registrieren und weitere Rabatte zu erhalten!",
    addFavorites:'Fügen Sie jetzt Ihre Favoriten zu Ihrer Wunschliste hinzu!',









    // Me Setting
    sure_delete_address: 'Sind Sie sicher, dass Sie diese Adresse löschen wollen', //google
    change_successful: 'Änderung erfolgreich!', //google
    change_failed: 'Änderung fehlgeschlagen!', //google
    save_successful: 'Speichern erfolgreich!', //google
    save_failed: 'Speichern fehlgeschlagen!', //google
    change_email: 'E-Mail ändern', //google
    your_contact_email: 'Ihre Kontakt-/Abonnementadresse:', //google
    send_confirm_email: 'Senden Sie eine Bestätigungs-E-Mail',
    your_new_account: 'Ihr neues Konto',
    my_payment_options:'Meine Zahlungsoption',
    change_password: 'Passwort ändern', // changePassword
    about: 'Über',
    sure_delete_card: 'Sind Sie sicher, dass Sie diese Kreditkarte entfernen m\u00f6chten', // remove_credit_card
    current_password:"Aktuelles Passwort",
    new_password:"Neues Passwort",
    must_new_password:"Confirm Password muss gleich dem neuen Passwort sein.",
    confirm_pwd: 'Bestätige das Passwort',
    old_new_password:"Das alte und das neue Kennwort können nicht identisch sein",
    expired_in:"Abgelaufen in",
    view_rules:"Regeln ansehen",
    vip_rewards:"{name} VIP BELOHNUNGEN",
    level_up_unlock:"LEVEL AUFSTEIGEN UND FREISCHALTEN",
    view_rewards:"View Rewards",
    points_redeem_success:'Einl\u00f6sung erfolgreich!',
    faqs:"FAQS",
    your_vip_level_to:"Dein VIP-Level wurde auf angehoben",
    your_new_rewards:"sieh dir deine neuen Belohnungen an!",
    points_history:"Punkteverlauf",
    expriring_soon:'Verfallen Bald',
    how_to_get_points:'Wie bekomme ich Punkte?',
    buy_and_earn:'Kaufen und verdienen',
    shop_now:'Jetzt einkaufen',
    review:"Überprüfung",
    suggestion:"Anregung",
    refer_a_friend:'Empfehlung an einen Freund',
    survey:"Umfrage",
    more_ways:"Mehr M\u00f6glichkeiten",
    hot_use_points:'Wie werden Punkte verwendet?',
    redeem_coupon:'Coupon einl\u00f6sen',
    apply_points:'Wenden Sie Punkte für den Kauf an',
    all:'Alle',
    recived:'Empfangen',
    redeem_coupon:'Coupon einl\u00f6sen',
    used:'Gebraucht',
    expired:'Abgelaufen',
    wishlist:'Wunschliste',
    delete:'L\u00f6schen',
    unused_coupon: 'Unbenutzte Gutscheine', // unused_coupons
    expired_coupon: 'Abgelaufene Coupons', // expired_coupons
    expired_coupon_remove: 'Abgelaufene Coupons werden nach 7 Tagen aus dem Couponfach entfernt.', // expired_coupoons_message
    yes:'Ja',
    no:'Nein',
    bust_size:'Brustumfang',
    bra_size:'BH-Gr\u00f6ße',
    what_your_preference:'Was ist deine Präferenz?',
    large:'Groß',
    small:'Klein',
    my_measurements:'Meine Maße',
    preference_cannot_empty: "Meine Einstellungen dürfen nicht alle leer sein", // my_preference_error_message
    my_preference:'Meine Vorliebe',
    who_you_buy_clothes_for:'Für wen kaufst du normalerweise Kleidung?',  // who_do_you_ususlly
    which_catehories_you_favor:'Welches sind Ihre Lieblingskategorien?', // whitch_are_your_favorite
    which_style_you_favor: 'Welches sind deine Lieblingsstile?', // whitch_are_your_styles
    bio:'Bio',
    name:'Name',
    first_name: 'Vorname', // firstName
    last_name: 'Nachname', // lastName
    nick_name:'Spitzname',
    change_profile_picture:'Profilbild ändern',
    edit_profile: 'Profil bearbeiten', // editProfile
    empty_here: "Hier ist es leer", // google
    ticket:'Fahrkarte',
    activities:'Aktivitäten',
    promo:'Promo',
    others:'Andere',
    news:'Neuigkeiten',
    orders:'Bestellungen',
    message:'Nachricht',
    about_web: 'Über {website}',
    setting: 'Einstellungen', // settings
    wallet_credit: 'Wallet-Guthaben',
    only_apply_for: 'Gilt nur für {website}-Einkäufe', // only_applicable
    wallet_history: 'Wallet-Verlauf',
    earned: 'Verdiente',
    wallet: 'Brieftasche',
    expired_on: 'Abgelaufen am: {date}',  // expied_on
    order: 'Bestellung', // order_wallet
    get_more_points:"Holen Sie sich mehr Punkte",
    share_friend_points_shop:"Sie erhalten bis zu <span class=\"font-red\">500 Punkte</span>,und Ihr Freund erh\u00E4lt bis zu <span class=\"font-red\">1000 Punkte</span> zum Einkaufen!", // CM1158
    for_limited_time_only:"For limited time only!", // CM1159
    how_does_it_works:"Wie funktioniert es?", // CM1161
    share_the_link:"Link teilen", // CM1162
    reward:"Belohnung", //CM0630_1
    copy_fail: 'Kopie fehlgeschlagen! Versuchen Sie es erneut', // google
    get_more_rewards:"M\u00F6chtest du mehr Belohnungen bekommen?", //CM1164
    friends_invited:"Freunde eingeladen", // CM1167
    points_earned:"Verdiente Punkte", // CM1168
    share_with:"TEILEN MIT", // CM1170
    share_friend_max_10:"Der Link kann mit {value} Freunden gleichzeitig geteilt werden", //CM1166
    email_title:"E-Mail-Titel", //CM1155
    open_to_points_reward:"\u00D6ffnen, um Mystery Points zu erhalten", //CM1242
    email_notes:"E-Mail-Notizen", //CM1156
    share_textarea_description:"{name} hat so viele Must-Haves! Registrieren Sie sich jetzt f\u00FCr Punktepr\u00E4mien, um bei {name} einzukaufen. {value}. Verpassen Sie diese Chance nicht!", // CM1243
    share_via_email:"Per E-Mail teilen", // CM1151
    share_extra_game_play:"Klicken Sie auf den Link und holen Sie sich ein zusätzliches Spiel", //google
    share_draw_luck_gifts:"{name} Black Friday Glücksverlosung. Spielen Sie mit und gewinnen Sie iPhone 13, {name} Beauty-Favoriten, Bonuspunkte, Coupons & mehr.",  //google
    success:"Erfolg", //CM0558
    order_no: 'Bestellung Nr', // orderNo
    item:'Artikel',
    track:'Spur',
    pay_now:'ZAHL JETZT',  // paynow
    remaining:'Restliche Zahlungszeit',
    repurchase:'R\u00fcckkauf',
    to_review:'Zu \u00fcberpr\u00fcfen',
    add_success:'Erfolg hinzufügen',
    add_failed:'Hinzufügen fehlgeschlagen',
    unpaid:'Unbezahlt',
    processing:'Verarbeitung',
    shipped:'Bestellung versandt',
    returns:'Rückkehr',
    history_orders_message:'Klicken Sie hier, um Ihre vorherigen Bestellungen zu überprüfen.',
    order_details:'Bestelldetails',
    your_package_is_packed:'Ihr Paket wird gepackt',
    it_normally_process_your_order:'Die Bearbeitung Ihrer Bestellung dauert normalerweise 3-7 Werktage.',
    your_package_signed_for:'Ihr Paket wurde signiert.',
    if_you_cosutomer_services:'Bei Fragen wenden Sie sich bitte an den Kundenservice.',
    payment_date:'Ihr Paket wird gepackt',  // paymentDate
    related_order_no: 'Related Order No', // relatedOrderNo
    shipping_price: 'Versandpreis', // shippingPrice
    order_total: 'Bestellsumme', // orderTotal
    insurance:"Transportversicherung", // shippingInsurance
    shipping_address:'Lieferadresse',
    billing_address:'Rechnungsadresse',
    shipping_method:'Versandmethode',
    delivery_time:'Lieferzeit',
    return:'Zurück',
    often_bought_with:'H\u00e4UFIG GEKAUFT MIT',
    cancel_order: 'Bestellung stornieren', // cancelOrder
    return_label:"Return Label", // returnlabel
    confirm_cancel: 'Stornierung best\u00e4tigen',
    continue_to_pay:'Weiter bezahlen',
    you_sure_cancel:'Sind Sie sicher, dass Sie die Zahlung stornieren möchten? Wenn Ihre Bestellung nicht bezahlt ist',
    it_will_cancel:'Es wird storniert',
    select_reason:'W\u00e4hlen Sie einen Grund',
    reason_submit_success_message: "Erfolgreich abgeschickt! Möchten Sie das Produkt erneut in Ihre Einkaufstasche legen?", // google
    no_comment_order:'Sie haben ungeprüfte Bestellungen, gehen Sie und teilen Sie Ihre Erfahrungen, um mehr Punkte zu sammeln!',
    you_have_no_orders: "Du hast noch keine Bestellungen, such dir deine Lieblingsprodukte aus!", // youHaveNoOrders
    go_shopping: 'Einkaufen', // goShopping
    default: 'Standard',
    do_not_have_enough_points: 'Sie haben nicht genügend Punkte, um diesen Coupon einzul\u00f6sen.', // points_not_enough_text
    use_points_redeem_coupon: 'M\u00f6chten Sie diesen Coupon mit {points} Punkten einl\u00f6sen?', // points_check_redeem_text
    redeem:'Einl\u00f6sen', // points_redeem
    redeemed:'Eingel\u00f6st',
    use_points_to_redeem:'Verwenden Sie Punkte, um weitere Coupons einzul\u00f6sen!', // use_points_redeem_coupon
    upload_image: 'Bild hochladen',
    image_formate:'Maximal 3 Fotos, nur JPEG, GIF oder PNG.', // maxinum_three_photos
    screen_freeze:'Bildschirm einfrieren',
    app_crush:'App verknallt',
    black_screen:'Schwarzer Bildschirm',
    device_reboot: 'Neustart des Geräts', // device_rebot
    running_slowly:'Langsam laufen',
    other_error:'Anderer Fehler',
    update_success:'Erfolg aktualisieren',
    question_type:'Fragetyp',
    time:'Zeit',
    suggestion_placeholder:'Entschuldigen Sie die Unannehmlichkeiten, wir werden das Problem so schnell wie m\u00f6glich beheben…', // sorry_inconvenience
    points: 'Punkte',
    please_check_rules:'Bitte überprüfen Sie die Regelseite auf Regeln für Text- und Bildpunkte',
    rating:"Bewertung", // google
    very_satisfied:'Sehr zufrieden',
    satisfied:'Zufrieden',
    normal:'Normal',
    bad:'Schlecht',
    poor:'Arm',
    order_review_placeholder:'Verdienen Sie {point} Punkte für Kommentare über {wordNum} Zeichen... *', // google
    earn_more:'Verdienen Sie mehr {points} Punkte für Kommentare mit Bildern',
    my_size_infomation:'Meine Größeninformationen (optional)',
    earn_more_for_size:'Verdienen Sie mehr {points} Punkte, um alle Größen zu füllen',
    comments_not_empty:"Kommentare dürfen nicht leer sein!", // google
    order_confirmed:"Bestellung best\u00e4tigt", // order_confirm
    order_detail_view:'Sie können Bestelldetails anzeigen, um diese Bestellung zu überprüfen.',
    order_confirm_success:'Bestellung erfolgreich best\u00e4tigt!',
    view_order:'Bestellung anzeigen',
    order_confirmed_enter_message: 'Der/die Artikel ist/sind noch unterwegs, sind Sie sicher, dass Sie auf "Bestätigen" klicken wollen, um ihn zu erhalten?', //google
    also_awaiting_review:'TDiese Bestellungen warten ebenfalls auf \u00fcberpr\u00fcfung',
    shop_the_reviewd_items:'Kaufen Sie die hoch bewerteten Artikel ein',
    add_to_bag_success:'Zur Tasche erfolgreich hinzugefügt!',
    logisticsInfo:'Logistikinformationen',
    tracknum:'Auftragsnummer',
    logisticsCompany:'Logistikunternehmen',
    trackingDetailInfo:'Tracking Detail Information',
    currentStatus:'Aktueller Status',
    cancel_return:'R\u00fcckgabe abbrechen',
    edit_return_receipt:'R\u00fccksendeschein bearbeiten',
    return_receipt:'R\u00fccksendeschein',
    return_record:'Return Record',
    return_canceled:'R\u00fcckgabe abgebrochen',
    total_refund:'Gesamtr\u00fcckerstattung',
    are_you_want_cancel_return:'Möchtest du die R\u00fccksendung wirklich stornieren?',
    upload_receipt:'Quittung hochladen',
    a_pooto_package_and_tracking_number:'Ein Foto der Quittung des Versandunternehmens mit dem Gewicht des Pakets und der Sendungsverfolgungsnummer.',
    courier_company:'Kurierunternehmen',
    select_courier_company:'Bitte w\u00e4hlen Sie das Kurierunternehmen', // please_select_courier_company
    fillin_courier_cpmpany:'Bitte geben Sie Ihr Kurierunternehmen an',
    not_be_empty:'kann nicht leer sein',
    check_order:'Sie können Ihre Bestelldetails überprüfen, um den Fortschritt Ihrer Rücksendung zu sehen.',
    return_logistics:'Rückhollogistik',
    find_similar:'Ähnliches finden',
    history_orders:'Historie Bestellungen',
    dont_want_rate: 'Sind Sie sicher, dass Sie diese Bestellung nicht bewerten möchten? Sie erhalten ',

    help_you: 'Wie kann ich dir helfen?',
    message_us: 'Nachricht uns',
    tickets: 'Tickets',
    search_holder: 'Produkte, Lieferung, Zahlung...',
    search_results: 'Suchergebnisse',
    search_count: '{count} Ergebnisse für "{key}"',
    section_articles: 'Artikel in diesem Abschnitt',
    status_replied: 'Antwortete',
    status_unreplied: 'Unbeantwortet',
    status_resolved: 'Aufgelöst',
    last_message: 'Letzte Nachricht',
    image: 'Bild',
    ticket_id: 'Ticketnummer',
    subject_size_color: 'Größe / Farbeinstellung',
    subject_address: 'Lieferadresse ändern',
    subject_shipping: 'Versandstatus oder ETA-Anfrage',
    subject_wrong: 'Beschädigter oder falscher Artikel erhalten',
    subject_shippingmethod: 'Upgrade-Versandart',
    subject_return: 'Rückgabe oder Austausch',
    subject_refund: 'Rückerstattung anfordern / Bestellung stornieren',
    payment_time: 'Zeitpunkt der Zahlung',
    response_time: 'Erwartete Antwortzeit: 1 Werktag',
    select_order: 'Bitte wählen Sie Ihre Bestellung',
    order_status_confirmed: 'Bestätigt',
    order_status_canceled: 'Abgebrochen',
    order_status_pendding: 'steht aus',
    order_status_paid: 'Bezahlt',
    order_status_partially_refunded: 'Teilweise erstattet',
    order_status_refunded: 'Zurückerstattet',
    order_status_held: 'Gehaltenen',
    rate: 'Bewertung',
    unsatisfied: 'Unzufrieden',
    you_can_rate: 'Sie können den Kundenservice hier kommentieren',
    please_rate: 'Bitte bewerten Sie meinen Service',
    // support:
    click:'Für alle Fragen und Antworten klicken Sie bitte auf unsere',
    page:"Seite",
    find_nothing:"Kann die gesuchte Antwort nicht finden?",
    order_processing: "Auftragsabwicklung",
    tracking:"Logistik-Tracking",
    return_tag:"Rückgabe & Rückerstattung",
    product_tag:"Produkt & Lager",
    payment_tag:"Zahlung & Promos",
    account_isssues:"Kontoprobleme",
    // contact:
    ticket_time:'NUR für After-Sale-Ausgabe（Stornieren/Bearbeiten Sie Ihre Bestellung/Versandstatus/Rückgabe/Umtausch usw.)',
    online_time: 'NUR für Vorverkaufsausgaben. Online-Verkäufe haben keinen Zugriff auf Bestellinformationen.',
    result_for: "Ergebnisse für \"<strong>{result}</strong>\"",
    question:"Haben Sie weitere Fragen?",
    article_helpful:"War dieser Artikel hilfreich?",
    related:"Ähnliche Artikel",
    // ticket
    noTicket:"kein Ticket",
    // order
    // ticket add
    textarea_placeholder:"Geben Sie hier eine Nachricht ein...",
    response_time:"Erwartete Reaktionszeit: Innerhalb von 24 Stunden",
    select_tip: 'Bitte wählen Sie Ihren Fragetyp',
    status_wating: 'Warte auf Antwort',
    // question ticket
    submit_tips: "Die folgenden Gründe sind optional. Sie können auf „X“ klicken, wenn Sie keines davon auswählen möchten.",
    description_ph: 'Bitte beschreiben Sie Ihr Problem so genau wie möglich, damit der Kundenservice schneller reagieren kann...',
    upload_image: 'Bild hochladen',
    rate:'Bewerten Sie meinen Service',
    rate_info: "Wir würden gerne hören, was Sie von unserem Kundenservice halten, damit wir Sie besser bedienen können.",
    rate_text_placeholder: 'Hier können Sie den Kundenservice kommentieren.',
    status_wating_apply:'Warte auf Bewerben',
    facebook_check: 'Wenn Sie dies überprüfen, senden wir Ihnen den Status der Sendungsverfolgung und Bestellinformationen in Messenger',
    return_the_oder: 'Bestellung zurücksenden',
    popular_search: 'Beliebte Suchanfragen:Rückerstattung,Rückgabe,Versand',
    vip_service: 'VIP-Service',
    copied_successfully:"Erfolgreich kopiert", // CM1244
    share_the_code:"Code teilen", // CM1165

    out_of_stock_policy:"Out-of-Stock-Richtlinie",
    check_mail_box:"Ein Bestätigungslink wurde an Ihre E-Mail-Adresse gesendet, bitte überprüfen Sie Ihr Postfach.",
    success_will_send_latest:"Erfolgreich! Wir werden unseren letzten Newsletter an Ihre neue E-Mail-Adresse senden.",
    upload_file_size_limit: 'Die Dateigröße muss unter 2MB liegen',

    order_processing_shipping_date: 'Wir brauchen normalerweise 3 bis 7 Werktage, um Ihre Bestellung zu bearbeiten.', // shippingDate
    my_order:"Meine Bestellung",
    my_services:"Mein Service",

    set_password_more_discount:"Legen Sie Ihr Passwort fest, um mehr Rabatt zu erhalten!",   //google

    time_must_current:"Das Geburtsdatum muss vor der aktuellen Uhrzeit liegen!",

    recently_viewed: "Zuletzt angesehen", 
    you_not_recently_view:"Sie haben nichts angesehen.", 
    website_trends:"{website} Trends",

    verify_check_order: "Prüfe die Reihenfolge",
    shipping_cost: "Versandkosten",// shippingCost
    register_and_get: 'Registrieren Sie sich bei {siteName}, um Ihre Bestellung zu verfolgen und mehr Rabatt zu erhalten!',
    please_input_password_first:'Bitte geben Sie zuerst das Passwort ein',
    check_order_msg: 'Jetzt können Sie Ihre Bestellung in Ihrem Konto überprüfen. Bitte überprüfen Sie "Meine Bestellung" unter "Ich", um Ihre Bestellung zu verfolgen.',
    total_count_pages: "Insgesamt {count} Seiten",
    saved: "Gespart",
    use_max_coupon: "Vergessen Sie nicht, den {coupon} RABATT-Gutschein zu verwenden!",
    up_to_saving: "bis zu {coupon} Ersparnis",

    please_select_order_first: 'Bitte wählen Sie zuerst eine Bestellung aus!',
    
    exprct_to_save: "Erwarten Sie zu speichern",
    max_save: "Gespart",
    step_1_address_content: "Ändern Sie Ihre Lieferadresse, um Artikel zu sehen, die an einen anderen Ort versendet werden. ",
    next_step: "Nächster Schritt",
    step_2_select_content: "Klicken Sie hier, um Artikel auszuwählen, die Sie zur Kasse benötigen.",
    got_it: "Habe es",
    step_2_checkout_content: "Wenden Sie im nächsten Schritt einen Gutscheincode und/oder {site} -Punkte an.",
    shipping_fee: "Versandgebühr",
    more_products: "Weitere Produkte",
    change: 'verändern',

    bottomLengthNote: "Messen Sie vom Bund bis zur Beinöffnung oder zum Saum.",
    inseam: "Schrittlänge",
    inseamNote: "Messen Sie die Länge von der Schrittnaht bis zum unteren Ende des Beins.",
    yourBustNote:"Messen Sie den Umfang an der breitesten Stelle Ihrer Brust.",
    yourUnderBust: "Deine Unterbrust",
    underBustNote: "Messen Sie den Umfang über der Unterlinie Ihrer Brust.",
    swimBustNote: "Messen Sie den Umfang an der breitesten Stelle Ihrer Brust.",
    swimUnderBustNote:"Messen Sie gerade über das Unterbrustband von Kante zu Kante.",
    swimWaistNote: "Messen Sie Ihre Taille an der dünnsten Stelle.",
    swimHipNote: "Messen Sie den vollsten Teil Ihrer Hüften.",
    switchTo: "Wechseln zu",

    underBust: 'unter Fehlschlag',
    bustDifference: "Fehlschlag - Unterschied unter Fehlschlag",
    expires_soon: "Läuft bald ab",
    sign_in_check_coupon:"Melden Sie sich an, um weitere Gutscheine zu überprüfen",

    footWidth: 'Fußbreite',
    footLengthNote: 'Messen Sie die maximale Länge Ihres Fußes.',
    footWidthNote: 'Messen Sie die maximale Breite Ihres Fußes.',

    wholesale: "Gro\u00DFhandel", // CM0828
    
    pre_wholesale: "PRE-Großhandel",
    after_wholesale: "AFTER-Großhandel",

    apply_before_expires: "Wenden Sie es an, bevor es abläuft!",
    gift_has_been_cart: "Das Geschenk wurde Ihrem Einkaufswagen hinzugefügt!",
    mystery_gifts_giveaway:"geheimnisvolle Geschenke & großes Werbegeschenk",

    got_extra_chance_to_win: "Sie haben ein exklusives VIP-Angebot und eine EXTRA CHANCE auf einen großen Preis!",
    collect_and_draw: 'Jetzt Sammeln & Ein Los Ziehen',

    how_rate_website: "Wie würden Sie {website} bewerten?",
	please_rate_by_stars: "Bitte bewerten Sie {website} nach Sternen: 1 bis 5",
    close: "Schließen",


    purchase_protection: "Zahlungsschutz",
    efficient_logistics: "Effiziente Logistik",
    customer_service: "Kundenservice",
    privacy_protection: "Datenschutz",
    proceed_to_checkout: "Zur Kasse",

    enjoy_discount_add_items: "Genießen Sie Rabatte nur, wenn Sie Artikel aus dieser Liste zu Ihrem Warenkorb hinzufügen.",
    add_on_items: "Add-on-Artikel",

    with_shipping_price: 'mit Versandkosten',
    modify: 'ÄNDERN',
	please_sure_address_correct: 'Bitte vergewissern Sie sich, dass die von Ihnen eingegebene Adresse korrekt ist',
    login_to_receive: "Anmelden um zu empfangen",

    bottoms: 'UNTERTEILE',
    coat: "MANTEL",
    lingerie: 'UNTERWÄSCHE',
    tops: 'OBERTEILE',

    addOnItemMsg: 'Zusatzrabatt',
    extraDiscount: 'Zusätzlicher Rabatt',
    cheapThanAdd: 'Günstiger als Extra',

    can_find_your_size:"Sie können Ihre Größe nicht finden?",
    tell_us_size: "Nennen Sie uns Ihre Größe",
    thanks_your_feedback: "Vielen Dank für Ihr Feedback.",
    submit_successful: "Erfolgreich einreichen", 
    we_found_similar_size: "Ähnliche Artikel haben wir auch in der Größe für Sie gefunden",
    we_optimize_based_feedback:"Wir werden den Größenbereich basierend auf Ihrem Feedback optimieren.",
    similar_item: "Ähnliche Artikel",
    not_find_anything_similar_search: "Es tut uns leid, dass wir nichts finden konnten, was dem ähnelt, wonach Sie gesucht haben.",

    smaller_than: "Kleiner als",
    larger_than: "Größer als",
    spin_to_win_for_mom: 'Drehen Sie, um für Mama zu gewinnen!',
    not_your_vibe_there_more: 'Nicht geeignet? Es gibt mehr!',
    picks_for_you: 'Wähle für dich aus',
    add_to_home_screen:"{name} zum Startbildschirm hinzufügen",

    collect_share: 'Sammeln und teilen',
    link_expired: 'Entschuldigung, dieser Link ist abgelaufen',
    link_valid_for_7_days: 'Es war nur 7 Tage lang gültig, nachdem es geteilt wurde.',
    share_coupon_expired: 'Entschuldigung, der geteilte Coupon ist abgelaufen',
    reach_coupon_limit: 'Entschuldigung, Sie haben das Limit für Coupon-Ansprüche erreicht',

    your_app_is_outdated: 'Ihre App-Version ist veraltet.',
    user_time_limit: 'Entschuldigung, Sie haben den Gutschein bereits erhalten. Er kann nicht erneut beansprucht werden.',

    share: "teilen",
    collect_now: "Jetzt sammeln",
    gift_card_receive: 'Eine exklusive Geschenkkarte wurde in Ihrem Gutschein-Paket hinterlegt',
    get_gift_card_from_share: "Sie erhalten eine exklusive Geschenkkarte, die von Ihren Freunden geteilt wurde",
    limited_discount: 'Begrenzter Rabatt',
    enjoy_free_shipping: 'Kostenloser Versand Genießen',

    set_the_password: "Das Passwort festlegen",
    send_subscribe_message: "Klicken Sie, um Werbenachrichten von {website} über WhatsApp oder Messenger zu akzeptieren!  Sie können sich jederzeit wieder abmelden.",
    enter_whatsapp_number: "Bitte geben Sie Ihre WhatsApp-Nummer ein",
    send_to_whatsapp: "An WhatsApp senden",
    please_select_location: "Bitte wählen Sie Ihren Standort",
    subscribe_success: "Erfolgreich abonnieren!",
    subscribe_filed: "Abonnieren fehlgeschlagen!",
    whatsapp_number: "WhatsApp-Nummer",
    receive_offers_message: "Ich möchte exklusive Angebote und Neuigkeiten per SMS erhalten.",

    status:'Status',
    wish_list_filter_empty: 'Keine Ergebnisse gefunden. Versuchen Sie verschiedene Optionen.',
    wish_list_nothing: 'Sie haben derzeit nichts gespeichert. Markieren Sie einen Artikel mit \'Herz\', um Ihre Wunschliste zu erstellen.',
    click_to_draw: 'Klicken Sie zum Zeichnen',

    wrong_zip_code_us: 'Die Postleitzahl sollte 5 Ziffern oder 5 Ziffern gefolgt von einer 4-stelligen Nummer sein, z.B. 20001 oder 20001-0000.',
    wrong_zip_code_uk: 'Die Postleitzahl sollte 6-8 Buchstaben, Zahlen und Leerzeichen im Format M2 5BQ, CW8 3AD, TN37 7HL enthalten.',
    wrong_zip_code_br: 'Die Postleitzahl muss 8 Ziffern enthalten, die durch einen Bindestrich getrennt sind, im folgenden Format: 12345-123.',
    wrong_zip_code_mx: 'Die Postleitzahl sollte eine 5-stellige Nummer sein, z.B. 12345.',
    wrong_zip_code_de: 'Die Postleitzahl sollte aus mindestens 5 Ziffern bestehen. Beispiel: 12345.',
    wrong_zip_code_fr: 'Die Postleitzahl muss 5 Ziffern enthalten, z.B. 12345.',
    wrong_zip_code_es: 'Die Postleitzahl muss 5 Ziffern enthalten. Die erste Ziffer darf nicht 6, 7, 8 oder 9 sein.',
    wrong_zip_code_it: 'Die Postleitzahl sollte eine 5-stellige Nummer sein, z.B. 12345.',
    wrong_zip_code_at: 'Die Postleitzahl sollte aus 4 Ziffern bestehen. Beispiel: 1234.',
    wrong_zip_code_be: 'Die Postleitzahl sollte aus 4 Ziffern bestehen. Beispiel: 1234.',
    wrong_zip_code_au: 'Die Postleitzahl sollte eine 4-stellige Nummer sein, z.B. 1234.',
    wrong_zip_code_bg: 'Die Postleitzahl sollte eine 4-stellige Nummer sein, z.B. 1234.',
    wrong_zip_code_ca: 'Das Postleitzahlenformat muss folgendermaßen aussehen: Buchstaben + Zahlen + Buchstaben + Leerzeichen + Zahlen + Buchstaben + Zahlen, z.B. A1B 2C3.',
    wrong_zip_code_ch: 'Die Postleitzahl sollte aus 4 Ziffern bestehen. Beispiel: 1234.',
    wrong_zip_code_cy: 'Die Postleitzahl sollte eine 4-stellige Nummer sein, z.B. 1234.',
    wrong_zip_code_cz: 'Die Postleitzahl sollte aus 5 Ziffern und 1 Leerzeichen im Format 123 45 bestehen.',
    wrong_zip_code_ee: 'Die Postleitzahl sollte eine 5-stellige Nummer sein, z.B. 12345.',
    wrong_zip_code_fi: 'Die Postleitzahl sollte eine 5-stellige Nummer sein, z.B. 12345.',
    wrong_zip_code_gr: 'Die Postleitzahl sollte aus 5 Ziffern und 1 Leerzeichen im Format 123 45 bestehen.',
    wrong_zip_code_hr: 'Die Postleitzahl sollte eine 5-stellige Nummer sein, z.B. 12345.',
    wrong_zip_code_ie: 'Die Postleitzahl sollte 7 Buchstaben, Zahlen und Leerzeichen im Format A65 F4E2, T45 D868, T12 FPK5 enthalten.',
    wrong_zip_code_lt: 'Die Postleitzahl sollte eine 5-stellige Nummer sein, z.B. 12345.',
    wrong_zip_code_lu: 'Die Postleitzahl sollte eine 4-stellige Nummer sein, z.B. 1234.',
    wrong_zip_code_lv: 'Die Postleitzahl sollte eine 4-stellige Nummer sein, z.B. LV-1234',
    wrong_zip_code_nl: 'Die Postleitzahl sollte aus 4 Ziffern gefolgt von einem Leerzeichen und 2 Buchstaben bestehen, z.B. 2561 DV.',
    wrong_zip_code_no: 'Die Postleitzahl sollte eine 4-stellige Nummer sein, z.B. 1234.',
    wrong_zip_code_nz: 'Die Postleitzahl sollte eine 4-stellige Nummer sein, z.B. 1234.',
    wrong_zip_code_pl: 'Die Postleitzahl sollte aus 5 Ziffern und einem Bindestrich im Format 12-345 bestehen.',
    wrong_zip_code_pt: 'Die Postleitzahl muss 7 durch einen Bindestrich getrennte Ziffern im folgenden Format enthalten: 1234-123.',
    wrong_zip_code_ro: 'Die Postleitzahl sollte eine 6-stellige Nummer sein, z.B. 123456.',
    wrong_zip_code_se: 'Die Postleitzahl sollte eine 5-stellige Nummer sein, z.B. 12345.',
    wrong_zip_code_dk: 'Die Postleitzahl sollte eine 4-stellige Nummer sein, z.B. 1234.',
    wrong_zip_code_us: 'Code postal : 5 chiffres ou 5 chiffres avec un numéro à 4 chiffres, par ex. 20001 ou 20001-0000',
    wrong_zip_code_uk: 'Code postal : 6-8 lettres, chiffres et espaces dans le format M2 5BQ, CW8 3AD, TN37 7HL',
    wrong_zip_code_br: 'Code postal : 8 chiffres séparés par un trait d\'union, au format suivant : 12345-123',
    wrong_zip_code_mx: 'Code postal : numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_de: 'Die Postleitzahl sollte aus mindestens 5 Ziffern bestehen. Beispiel: 12345',
    wrong_zip_code_fr: 'Code postal : 5 chiffres, par ex. 12345',
    wrong_zip_code_es: 'Code postal : 5 chiffres. La première chiffre ne peut pas être 6, 7, 8 ou 9',
    wrong_zip_code_it: 'Code postal : numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_at: 'Postleitzahl sollte aus 4 Ziffern bestehen. Beispiel: 1234',
    wrong_zip_code_be: 'Postleitzahl sollte aus 4 Ziffern bestehen. Beispiel: 1234',
    wrong_zip_code_au: 'Code postal : numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_bg: 'Code postal : numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_ca: 'Format du code postal : lettres + chiffres + lettres + espaces + chiffres + lettres + chiffres, par ex. A1B 2C3',
    wrong_zip_code_ch: 'Postleitzahl sollte aus 4 Ziffern bestehen. Beispiel: 1234',
    wrong_zip_code_cy: 'Code postal : numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_cz: 'Code postal : 5 chiffres et 1 espace, au format 123 45',
    wrong_zip_code_ee: 'Code postal : numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_fi: 'Code postal : numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_gr: 'Code postal : 5 chiffres et 1 espace, au format 123 45',
    wrong_zip_code_hr: 'Code postal : numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_ie: 'Code postal : 7 lettres, chiffres et espaces dans le format A65 F4E2, T45 D868, T12 FPK5',
    wrong_zip_code_lt: 'Code postal : numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_lu: 'Code postal : numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_lv: 'Code postal : numéro à 4 chiffres, par ex. LV-1234',
    wrong_zip_code_nl: 'Code postal : 4 chiffres avec un espace et se terminant par 2 lettres, par ex. 2561 DV',
    wrong_zip_code_no: 'Code postal : numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_nz: 'Code postal : numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_pl: 'Code postal : 5 chiffres et "-" au format 12-345',
    wrong_zip_code_pt: 'Code postal : 7 chiffres séparés par un trait d\'union, au format suivant : 1234-123',
    wrong_zip_code_ro: 'Code postal : numéro à 6 chiffres, par ex. 123456',
    wrong_zip_code_se: 'Code postal : numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_dk: 'Code postal : numéro à 4 chiffres, par ex. 1234',

    systemEmailNotice: 'Hinweis: Bitte aktualisieren Sie Ihre E-Mail-Adresse, um wichtige Benachrichtigungen von uns zu erhalten. Klicken Sie hier, um jetzt zu aktualisieren.',
    wishListEmptySignIn: 'Wunschliste leer. Melden Sie sich an, um Ihre Wunschliste anzuzeigen.',
    country_code: "Ländercode",
    select_country_code: "Bitte wählen Sie den richtigen Ländercode aus",

    local_policy_pay_tax: "Gemäß der lokalen Politik müssen Kunden bei Auswahl dieser Zahlungsmethode eine Steuer auf Devisentransaktionen zahlen.",
    returning: "Rückgabe",
    select_to_be_returned: "Bitte wählen Sie den/die Artikel, die zurückgegeben werden sollen",
    cannot_be_returned: "Dieser Art von Artikel kann nicht zurückgegeben werden",
    are_being_returned: "Der/die Artikel werden zurückgegeben",
    please_select_the_items: "Bitte wählen Sie den/die Artikel aus",
    select_reason_all_be_returned: "Bitte wählen Sie den Grund, warum alle Artikel zurückgegeben werden sollen",
    please_select_the_reason: "Bitte wählen Sie den Grund",
    please_submit_further_details: "Bitte geben Sie weitere Details an",
    descrive_problem_customer_service_respond: "Beschreiben Sie Ihr Problem so genau wie möglich, damit sich der Kundenservice schneller darauf beziehen kann",
    require_support: "benötigen Unterstützung",

    download: "Herunterladen",
    download_all: "Alle herunterladen",
    download_products_images: "Produktbilder herunterladen",

    drop_shipping: "Dropshipping",
    limit_time_only:"NUR FÜR BEGRENZTE ZEIT",
    ruler_min: 'Das Lineal ist auf dem Minimum',
    ruler_max: 'Das Lineal ist auf dem Maximum',
    swiper_to_add: 'Wischen Sie, um Ihre Körpermaße hinzuzufügen',
    save_your_body_mea: 'Wir speichern Ihre Körpermaße, um eine perfekte Passform für zukünftige Einkäufe zu gewährleisten.',
    mea_perf: 'Präferenz',
    safe_payment_options: "Sichere Zahlungsoptionen",

    influencer_outfit: "Influencer-Outfit",

    sign_in_paypal:"Mit PayPal anmelden",
    ends_today: 'endet heute',

    selected: 'ausgewählt',
    addon_reach_limit: 'Sie haben das Limit für ausgewählte Zusatzartikel erreicht.Klicken Sie auf "Ausgewählt", um Ihre Auswahl rückgängig zu machen.',

    phone_number_or_email_address: "Telefonnummer oder E-Mail-Adresse",
    reset_password_via_email: "Passwort über E-Mail-Adresse zurücksetzen",
    reset_password_via_phone: "Passwort über Telefonnummer zurücksetzen",
    welcome_back: "Willkommen zurück",
    please_sign_in_account: "Bitte melden Sie sich mit Ihrem Konto an.",
    please_create_your_account: "Bitte erstellen Sie Ihr Konto.",
    code: 'Code',

    sign_in_with:"Mit {mode} anmelden",

    incorrect_email_address:"Falsche E-Mail-Adresse",


    privacy_protection: 'Datenschutz',
    security_privacy: 'Sicherheit und Datenschutz',
    register_success: "Sie haben sich erfolgreich registriert",

    type: 'Typ',
    spin_to_win: 'Drehen und Gewinnen',
    you_run_out_of_chance: "Ihnen sind die Chancen ausgegangen",
    get_all_in_app: 'Alles in der App erhalten',
    app_new_user_gift: 'App-Neukundengeschenk',
    ends_in: 'endet in',

    cvv_tip: 'Für eine erfolgreiche Zahlung geben Sie bitte Ihre Kreditkartenprüfnummer (CVV) ein. Die CVV-Nummer finden Sie auf der Rückseite Ihrer Kreditkarte.',

    promotion_details: "Aktionsdetails",
    estimated_price: "Geschätzter Preis",
    buy_now_enjoy: "Jetzt kaufen und folgende Vorteile genießen",
    discount_price: "Rabattpreis",
    original_price: "Ursprünglicher Preis",
    estimated: "Geschätzt",
    discount_shown_estimated: "Die angezeigten Rabatte sind geschätzt und unterliegen den Gutscheinregeln. Der endgültige Preis kann variieren.",
    promotions: "Aktionen",
    get_coupon: "Gutschein erhalten",
    best_coupon: "Bester Gutschein",

    app_exclusive: 'App-exklusiv',
    unsubscribe_message: 'Erneut abonnieren mit {email} auf {website} und erhalte Gutscheine.',
    subscribe_and_get: 'Erneut abonnieren & Gutscheine sichern',

    quick_ship: 'Schnellversand',
    provide_faster_delivery_service: 'wird einen schnelleren Lieferservice bereitstellen',
    between: 'zwischen',
    eligible_for_quick_ship: 'sind für Schnellversand berechtigt',

    consent_cookie_dsc: 'Wir verwenden Cookies und andere Tracking-Technologien, um Ihre Browser-Erfahrung auf unserer Website zu verbessern, Ihnen personalisierte Inhalte und gezielte Werbung zu zeigen, unseren Website-Verkehr zu analysieren und zu verstehen, woher unsere Besucher kommen.',
    consent_btn_settings: 'Cookie-Einstellungen',
    consent_btn_accept: 'Akzeptieren',
    consent_btn_decline: 'Ablehnen',
    consent_about: 'Über Cookies',
    consent_setting_1_title: 'Notwendige Cookies',
    consent_setting_1_status: 'Immer aktiv',
    consent_setting_1_dsc: 'Diese Cookies sind für die einwandfreie Funktion der Website unerlässlich. Sie ermöglichen grundlegende Funktionen wie die Seitennavigation und den Zugriff auf sichere Bereiche der Website. Die Website kann ohne diese Cookies nicht ordnungsgemäß funktionieren, und sie können nicht deaktiviert werden.',
    consent_setting_2_title: 'Analytische Cookies',
    consent_setting_2_dsc: 'Analytische Cookies liefern Informationen darüber, wie diese Website genutzt wird. Dies verbessert die Benutzererfahrung. Die erhobenen Daten werden aggregiert und anonymisiert.',
    consent_setting_3_title: 'Werbungs-Cookies',
    consent_setting_3_dsc: 'Werbungs-Cookies liefern Informationen über Benutzerinteraktionen mit ChicMe-Inhalten. Dies hilft uns, die Wirksamkeit des Inhalts unserer E-Mails und unserer Website besser zu verstehen.',
    consent_btn_accept_all: 'Alle akzeptieren',
    consent_btn_save_settings: 'Einstellungen speichern',
    from:'Aus'
}